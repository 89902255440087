import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Typography } from 'antd';
import Menu from '../../component/Menu/Menu';
import Footer from '../../component/Footer/Footer';
import { Div } from '../../framework';
import { setFlyoutOpen, setMenuMode } from '../../store/app.slice';

import './AboutUs.scss';

const { Title, Paragraph } = Typography;

const AboutUsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  return (
    <Div className='page-about-us'>
      <Menu />
      <Helmet>
        <title>About Us - Heycast.me</title>
        <meta
          name='description'
          content='The right role. The right talent. The right platform.'
        />
      </Helmet>
      <section className='statement'>
        <Title level={2}>About Us</Title>
        <Paragraph>
          Heycast.me is a groundbreaking job matching platform, specifically
          designed for the entertainment industry in Malaysia. Our platform is
          tailored to help brands, creative agencies, and video/film production
          companies connect with influencers, key opinion leaders, artists,
          acting talents, and models, all with the aim of digitalizing and
          elevating the development of the local entertainment industry.
        </Paragraph>
        <Paragraph>
          Our mission is to provide transparency and efficiency to the
          entertainment industry by leveraging technology. We believe that all
          creative talents deserve to be treated fairly and compensated
          appropriately, which is why we have built our platform to ensure that
          all parties involved benefit from a fair and equitable process. Our
          aim is to level up the playing field for local creative talents,
          providing them with a proper platform to showcase their skills and get
          matched with the right job opportunities.
        </Paragraph>
        <Paragraph>
          What sets Heycast.me apart from other job matching platforms is our
          commitment to bringing technology and human creativity together. By
          combining advanced AI algorithms with the expertise of industry
          professionals, we&apos;re able to provide hirers with the most
          suitable candidates for their projects, saving them time and costs.
          Additionally, we make it easy for creative talents to apply for the
          jobs that best fit their skillset, helping them to build their careers
          and achieve their goals.
        </Paragraph>
        <Paragraph>
          Heycast.me is proud to be a Cradle-endorsed and funded startup
          company, committed to transforming the entertainment industry in
          Malaysia and beyond.
        </Paragraph>
      </section>

      <Div noGrow className='footer'>
        <Footer />
      </Div>
    </Div>
  );
};

export default AboutUsPage;
